import React, { useRef, useEffect, useState } from 'react';
import './Hero.css';
import Logo from '../Visuals/4.svg';
import { Container, Button, Spinner, Col, Row } from 'react-bootstrap';
function Hero() {
    const headerRef = useRef(null);
    const [isImageLoaded, setImageLoaded] = useState(false); // State to track image loading
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }
    // Define the handleImageLoaded function here
    const handleImageLoaded = () => {
        setImageLoaded(true);
    };

    return (
        <div className="section">
            <div className="container">

                <Row className="mt-5">
                    <Col xs={12} lg={6}>
                        <div className="w-layout-blockcontainer container-9 w-container">
                            <img
                                alt=""
                                className="image-7"
                                src={Logo}
                                onLoad={handleImageLoaded} // Add the onLoad event here
                            />
                        </div>
                        <div className="hero-wrapper">
                            <div className="hero-split">
                                <a className="button-primary" href="http://www.nucleusamapp.com" target="">Get Started</a>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <Row className="mt-5">
                            <p className="herop">
                                Your central hub for operational excellence. With Nucleus, record pivotal insights, capture institutional knowledge, digitize any record, manage equipment, store training materials, and easily access all records. We believe in creating a unique Nucleus for every organization, ensuring you always operate at your peak.</p>
                        </Row>
                        <Row className="mt-5">
                        <h2 className="heroh2">
                            The Nucleus Platform:
                        </h2>
                        <div className="herolist">
                            <ul>
                                    <li> Helps retain institutional knowledge despite employee turnover.</li>
                                    <li> Provides a central repository for all your training materials.</li>
                                    <li> Allows custom form creation and record logging.</li>
                                    <li> Enables efficient equipment management.</li>
                                    <li> Streamlines maintenance management.</li>

                            </ul>
                            </div>
                        </Row>

                    </Col>
                </Row>
                
            </div>
            
        </div>
    );
}

export default Hero;
