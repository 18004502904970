import React, { Suspense, lazy } from 'react';
import { useParams } from 'react-router-dom';
import BlogNavbar from '../Blog/BlogNavbar'; // Corrected the import path
import './Blog.css'; // Common CSS file for blog posts

function BlogPost() {
    const { id } = useParams();
    const BlogComponent = lazy(() => import(`./Blogposts/${id}`)); // Corrected the dynamic import path

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <BlogNavbar />
            <BlogComponent />
        </Suspense>
    );
}

export default BlogPost;
