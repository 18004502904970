import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import './App.css';
import Overview from './components/Overview';
import Benefits from './components/Howitworks';
import About from './components/About';
import Features from './components/Features';
import Featureslist from './components/Featureslist';
import Pricing from './components/pricing';
import Getintouch from './components/getintouch';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import PrivacyPolicy from './components/PrivacyPolicy';
import BlogDash from './Blog/BlogDash';
import BlogPost from './Blog/BlogPost'; // Import BlogPost component

const tagManagerArgs = {
    gtmId: 'G-RZD7WWF7G7', // Your actual GTM Container ID
};

function usePageViews() {
    let location = useLocation();
    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'page_view',
                pagePath: location.pathname,
                pageTitle: document.title
            }
        });
    }, [location]);
}

function RouterSetup() {
    usePageViews(); // Initialize page view tracking here
    return (
        <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/blog" element={<BlogDash />} />
            <Route path="/blog/:id" element={<BlogPost />} /> {/* Dynamic route */}
        </Routes>
    );
}

function Main() {
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const section = params.get('section');
        if (section) {
            document.getElementById(section)?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location]);

    return (
        <div>
            <Navbar />
            <Hero />
            <About />
            <Features />
            <Benefits />
            <Getintouch />
            <Footer />
        </div>
    );
}

function App() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        TagManager.initialize(tagManagerArgs);
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <Router>
            <RouterSetup />
        </Router>
    );
}

export default App;
