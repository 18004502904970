import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = ({ setMenuOpen }) => { // Assuming setMenuOpen is passed as a prop

    const handleGoBackClick = (e) => {
        e.preventDefault();
        // Assuming your main app is at '/', change this if it's different
        window.location.href = '/';

        // If you have a specific section to scroll to, you can use:
        // document.getElementById('YourSectionId').scrollIntoView({ behavior: 'smooth' });

        // Close the navbar if it's open
        setMenuOpen(false);
    };

    return (
        <div className="privacy-policy-container">
            <a className="privacy-button" href="/" onClick={handleGoBackClick}>Go Back</a>
            <h2 className="h2header">Privacy Policy for Nucleus Asset Management</h2>
            <p className="last-updated">Last Updated: 20 November 2023</p>

            <h2 className="h2text">Introduction</h2>
            <p className="text">Welcome to Nucleus Asset Management. We are committed to protecting the privacy and security of our users. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our web application.</p>

            <h2 className="h2text">Information Collection and Use</h2>
            
                <li className="text"><strong>Personal Information:</strong> We collect information that identifies you, such as your name, email address, and company details, when you sign up for our service.</li>
                <li className="text"><strong>Usage Data:</strong> We gather data about how you and others use our web application, including interactions with maintenance management, equipment training, and lessons learned features.</li>
            

            <h2 className="h2text">Purpose of Data Collection</h2>
            <li className="text">To provide, operate, and maintain our web application.</li>
            <li className="text">To improve, personalize, and expand our web application.</li>
            <li className="text">To understand and analyze how you use our web application.</li>
            <li className="text">To develop new products, services, features, and functionality.</li>

            <h2 className="h2text">Data Sharing and Disclosure</h2>
            <li className="text">We may share your information with service providers who assist us in operating our web application.</li>
            <li className="text">We do not sell your personal data to third parties.</li>
            <li className="text">We may disclose your information if required by law or in response to legal requests.</li>



            <h2 className="h2text">Data Security</h2>
            <li className="text">We implement appropriate technical and organizational measures to protect your personal data from unauthorized access, alteration, disclosure, or destruction.</li>

            <h2 className="h2text">User Rights</h2>
            <li className="text">You have the right to request access to, correct, or delete your personal data.</li>
            <li className="text">You may opt-out of receiving marketing communications from us.</li>


            <h2 className="h2text">International Data Transfers</h2>
            <li className="text">Your information, including personal data, may be transferred to and maintained on computers located outside of your state, province, or country where the data protection laws may differ.</li>

            <h2 className="h2text">Changes to This Privacy Policy</h2>
            <li className="text">We reserve the right to update our Privacy Policy at any time. We will notify you of any changes by posting the new Privacy Policy on this page.</li>

            <h2 className="h2text">Contact Us</h2>
            <li className="text">If you have any questions about this Privacy Policy, please contact us at info@nucleusam.com.</li>
        </div>
    );
};

export default PrivacyPolicy;
