import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import '../components/Navbar.css';
import TagManager from 'react-gtm-module';

function DesktopBlogNavbar() {
    const navigate = useNavigate();

    const handleNavClick = (sectionId) => {
        navigate(`/?section=${sectionId}`);
    };

    return (
        <div className="navbar-wrapper-2">
            <a className="navbar-brand w-nav-brand" href="/">
                <img alt="" className="image-2" src="/favicon.png" width="80px" />
            </a>
            <nav className="nav-menu-wrapper w-nav-menu">
                <ul className="nav-menu-two w-list-unstyled">
                    <li>
                        <Link
                            className="nav-link"
                            to="/"
                            onClick={(e) => {
                                handleNavClick('About');
                                e.preventDefault();
                            }}
                        >About</Link>
                    </li>
                    <li>
                        <Link
                            className="nav-link"
                            to="/"
                            onClick={(e) => {
                                handleNavClick('Features');
                                e.preventDefault();
                            }}
                        >Features</Link>
                    </li>
                    <li>
                        <Link
                            className="nav-link"
                            to="/"
                            onClick={(e) => {
                                handleNavClick('Benefits');
                                e.preventDefault();
                            }}
                        >Benefits</Link>
                    </li>
                    <li>
                        <Link to="/blog" className="nav-link">Blog</Link>
                    </li>
                    <li>
                        <Link
                            className="nav-link"
                            to="/"
                            onClick={(e) => {
                                handleNavClick('Getintouch');
                                e.preventDefault();
                            }}
                        >Contact Us</Link>
                    </li>
                    <li><div className="nav-divider"></div></li>
                    <li className="mobile-margin-top-10">
                        <Link
                            className="button-primary-2 w-button"
                            to="/"
                            onClick={(e) => {
                                handleNavClick('Getintouch');
                                e.preventDefault();
                            }}>Book a free demo</Link>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

function MobileBlogNavbar() {
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();

    const handleNavClick = (sectionId, e) => {
        setMenuOpen(false);
        navigate(`/?section=${sectionId}`);
    };

    return (
        <>
            <div className="navbar-wrapper-2">
                <a className="navbar-brand w-nav-brand" href="/">
                    <img alt="" className="image-2" src="/favicon.png" width="60px" />
                </a>
                <div className="menu-button-2 w-nav-button" onClick={() => setMenuOpen(!menuOpen)}>
                    <FontAwesomeIcon icon={faBars} />
                </div>
            </div>
            {menuOpen && (
                <nav className="nav-menu-wrapper w-nav-menu">
                    <ul className="nav-menu-two w-list-unstyled">
                        <li>
                            <Link className="nav-link" to="/" onClick={(e) => handleNavClick('About', e)}>About</Link>
                        </li>
                        <li>
                            <Link className="nav-link" to="/" onClick={(e) => handleNavClick('Features', e)}>Features</Link>
                        </li>
                        <li>
                            <Link className="nav-link" to="/" onClick={(e) => handleNavClick('Benefits', e)}>Benefits</Link>
                        </li>
                        <li>
                            <Link to="/blog" className="nav-link">Blog</Link>
                        </li>
                        <li>
                            <Link className="nav-link" to="/" onClick={(e) => handleNavClick('Getintouch', e)}>Contact Us</Link>
                        </li>
                        <li><div className="nav-divider"></div></li>
                        <li className="mobile-margin-top-10">
                            <Link
                                className="button-primary-2 w-button"
                                to="/"
                                onClick={(e) => handleNavClick('Getintouch', e)}>Book a free demo</Link>
                        </li>
                    </ul>
                </nav>
            )}
        </>
    );
}

function BlogNavbar() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 991);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="navbar-logo-left-2">
            <div className="navbar-logo-left-container shadow-three w-nav">
                <div className="container-5">
                    {isMobile ? <MobileBlogNavbar /> : <DesktopBlogNavbar />}
                </div>
            </div>
        </div>
    );
}

export default BlogNavbar;
