import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <div className="footer">
            <div className="text-block">
                Or email / call us at: info@nucleusam.com
                <br />+27 79 096 5039
                <br />
            </div>
            <div className="privacy-link">
                <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" className="privacy-link">Privacy Policy</a>
            </div>

        </div>
    );
}

export default Footer;
