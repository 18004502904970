import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import TagManager from 'react-gtm-module';
import './Navbar.css';
import { Link } from 'react-router-dom';

function DesktopNavbar() {

    const handleNavClick = (sectionId) => {
        document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
        // Sending data to GTM
        TagManager.dataLayer({
            dataLayer: {
                event: 'navbar_click',
                section: sectionId
            }
        });
    };

    return (
        <div className="navbar-wrapper-2">
            <a className="navbar-brand w-nav-brand" href="/">
                <img alt="" className="image-2" src="/favicon.png" width="80px"  />
            </a>
            <nav className="nav-menu-wrapper w-nav-menu">
                <ul className="nav-menu-two w-list-unstyled">
                    
                    <li>
                        <a
                            className="nav-link"
                            href="#ABOUT"
                            onClick={(e) => {
                                handleNavClick('About');
                                e.preventDefault();
                                document.getElementById('About').scrollIntoView({ behavior: 'smooth' });
                            }}
                        >About</a>
                    </li>
                    <li>
                        <a
                            className="nav-link"
                            href="#Features"
                            onClick={(e) => {
                                handleNavClick('Features');
                                e.preventDefault();
                                document.getElementById('Features').scrollIntoView({ behavior: 'smooth' });
                            }}
                        >
                            Features
                        </a>
                    </li>
                    <li>
                        <a
                            className="nav-link"
                            href="#Benefits"
                            onClick={(e) => {
                                handleNavClick('Benefits');
                                e.preventDefault();
                                document.getElementById('Benefits').scrollIntoView({ behavior: 'smooth' });
                            }}
                        >
                            Benefits
                        </a>
                    </li>
                    <li>
                        <a>
                            <Link to="/blog" className="nav-link">Blog</Link>
                            
                        </a>
                        
                    </li>
                    <li>
                         <a
                            className="nav-link"
                            href="#Getintouch"
                            onClick={(e) => {
                                handleNavClick('Getintouch');
                                e.preventDefault();
                                document.getElementById('Getintouch').scrollIntoView({ behavior: 'smooth' });
                            }}
                        >
                            
                            Contact Us
                        </a>
                    </li>
                    <li><div className="nav-divider"></div></li>
                    <li className="mobile-margin-top-10">
                        <a className="button-primary-2 w-button"
                            href="#Getintouch"
                            onClick={(e) => {
                                handleNavClick('Getintouch');
                                e.preventDefault();
                                document.getElementById('Getintouch').scrollIntoView({ behavior: 'smooth' });
                            }}>Book a free demo</a>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

function MobileNavbar() {
    const [menuOpen, setMenuOpen] = useState(false);

    const handleLinkClick = (sectionId, e) => {
        setMenuOpen(false); // This line closes the navbar
        TagManager.dataLayer({
            dataLayer: {
                event: 'navbar_click',
                section: sectionId
            }
        });
    };

    return (
        <>
            <div className="navbar-wrapper-2">
                <a className="navbar-brand w-nav-brand" href="/">
                    <img alt="" className="image-2" src="/favicon.png" width="60px" href="/" />
                </a>
                <div className="menu-button-2 w-nav-button" onClick={() => {
                    console.log("Menu button clicked!");
                    setMenuOpen(!menuOpen);
                }}>
                    <FontAwesomeIcon icon={faBars} />
                </div>
            </div>
            {menuOpen && (
                <nav className="nav-menu-wrapper w-nav-menu">
                    <ul className="nav-menu-two w-list-unstyled">
                        <li><a className="nav-link" href="#ABOUT" onClick={(e) => handleLinkClick('About', e)}>About</a></li>
                        <li><a className="nav-link" href="#Features" onClick={(e) => handleLinkClick('Features', e)}>Features</a></li>
                        <li><a className="nav-link" href="#Benefits" onClick={(e) => handleLinkClick('Benefits', e)}>Benefits</a></li>
                        <li><a><Link to="/blog" className="nav-link">Blog</Link></a></li>
                        <li><a className="nav-link" href="#Getintouch" onClick={(e) => handleLinkClick('Getintouch', e)}>Contact Us</a></li>
                        <li><div className="nav-divider"></div></li>
                        <li className="mobile-margin-top-10">
                            <a className="button-primary-2 w-button" href="#Getintouch"
                                onClick={(e) => {
                                    e.preventDefault();
                                    document.getElementById('Getintouch').scrollIntoView({ behavior: 'smooth' });
                                    setMenuOpen(false); // This line closes the navbar
                                }}>Book a free demo</a>
                        </li>

                    </ul>
                </nav>
            )}
        </>
    );
}


function Navbar() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 991);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            // Cleanup the event listener
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="navbar-logo-left-2">
            <div className="navbar-logo-left-container shadow-three w-nav">
                <div className="container-5">
                    {isMobile ? <MobileNavbar /> : <DesktopNavbar />}
                </div>
            </div>
        </div>
    );
}

export default Navbar;

