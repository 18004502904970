import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Card, Col, Container, Row, Image, Spinner, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Blog.css'; // Ensure the CSS file is correctly named
import BlogNavbar from './/BlogNavbar';
import Blogimg1 from './Blog Images/Blog1.png';
import Blogimg2 from './Blog Images/Blog2.png';
import Blogimg3 from './Blog Images/Blog3.png';
import Blogimg4 from './Blog Images/Blog4.png';
import Blogimg5 from './Blog Images/Blog5.png';
import Blogimg6 from './Blog Images/Blog6.png';

const blogPosts = [
    {
        id: 'Blog1',
        icon: Blogimg1,
        title: 'What is institutional knowledge, and why should you be retaining it?',
    },
    {
        id: 'Blog2',
        icon: Blogimg2,
        title: 'Maximizing Efficiency: How Lessons Learned Can Transform Your Asset Management',
    },
    {
        id: 'Blog3',
        icon: Blogimg3,
        title: 'Unlocking Insights with AI: The Future of Asset Management',
    },
    {
        id: 'Blog4',
        icon: Blogimg4,
        title: 'Custom Reporting: Tailoring Data to Drive Decision Making',
    },
    {
        id: 'Blog5',
        icon: Blogimg5,
        title: 'Empowering Your Team: Easy Access to Training Materials with Nucleus',
    },
    {
        id: 'Blog6',
        icon: Blogimg6,
        title: 'From Wrenches to Laptops: The Evolution of Asset Management',
    },
];

function BlogDash() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000); // 5 seconds timer

        // Clean up the timer if the component unmounts
        return () => clearTimeout(timer);
    }, []);

    return (
        <Suspense >
            <BlogNavbar/>
        <Container className="my-5">
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            ) : (
                <>
                    <Row className="mb-4">
                        <Col>
                            <h1>Nucleus Blog</h1>
                        </Col>
                    </Row>
                    <Row>
                        {blogPosts.map((blog, index) => (
                            <Col key={index} sm={4} md={4} lg={4} className="mb-5">
                                <Card className="info-card">
                                    <Card.Body>
                                        <Link to={`/blog/${blog.id}`} className="no-underline">
                                            <Card.Title>
                                                <Image
                                                    src={blog.icon}
                                                    alt="Icon"
                                                    className="info-icon mb-2"
                                                />
                                            </Card.Title>
                                            <Card.Text className="blog-title">{blog.title}</Card.Text>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </>
                )}
                <Link to="/">
                    <Button variant="outline-success" >Back</Button> {/* Add the onClick handler */}
                </Link>
            </Container>
            
        </Suspense>

    );
}

export default BlogDash;
