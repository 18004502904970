import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import './About.css';
import About1 from '../About Images/1.svg';
import About2 from '../About Images/2.svg';
import About3 from '../About Images/3.svg';
import Hendri from '../About Images/Hendri.svg';





function About() {
    return (
        <Container className="Container my-5" id="About">
            <Row className="mb-4">
                <Col>
                    <h1>About Nucleus</h1>
                </Col>
            </Row>
            <Row>
                {infoBlocks.map((block, index) => (
                    <Col key={index} sm={4} md={4} ls={4} className="mb-5 ">
                        <Card className="info-card">
                            <Card.Body>
                                <Card.Title>
                                    <Image src={block.icon} alt="Icon" className="info-icon" />
                                </Card.Title>
                                <Card.Text>{block.text}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>

            <Row className="align-items-center mt-4">
                <Col md={6} className="mb-5">
                    <Image src={Hendri} roundedCircle className="founder-image" />
                </Col>
                <Col  md={6}>
                    <h3><span style={{ color: '#188754' }}>Founder:</span> Hendri Lartz</h3>
                    <p className="Paragraph">Hendri Lartz, the founder of Nucleus Asset Management, holds a bachelor's degree in engineering and nearly a decade of industry experience. The idea behind Nucleus stems from Hendri witnessing and personally experiencing the challenges industrial operations face daily due to high employee turnover and the loss of institutional knowledge as a consequence.</p>
                </Col>
            </Row>
        </Container>
    );
}

const infoBlocks = [
    {
        icon: About1,
        text: 'Nucleus Asset Management is a user-friendly, web-based platform designed to enhance an operation through knowledge retention.'
    },
    {
        icon: About2,
        text: 'Nucleus allows an organization to easily record, store, and access pivotal insights, institutional knowledge, training material, and equipment records.'
    },
    {
        icon: About3,
        text: 'The intuitive design of Nucleus creates a user-friendly platform which adds value from day one.'
    }
];
export default About;