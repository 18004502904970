import React, { useState, useRef } from 'react';
import './getintouch.css';

function Getintouch() {
    const [showThankYouModal, setShowThankYouModal] = useState(false)
    const formRef = useRef(null);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const form = e.target;

        // Use Fetch API to post the form data to Netlify
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams(new FormData(form)).toString(),
        })
            .then(response => {
                if (response.ok) {
                    setShowThankYouModal(true);
                    form.reset(); // Reset form on successful submission
                } else {
                    throw new Error('Network response was not ok.');
                }
            })
            .catch((error) => alert(error));
    };

    const closeThankYouModal = () => {
        setShowThankYouModal(false);
        formRef.current.reset(); // Reset form when modal is closed
    };
    return (
        <div className="gitdiv" id="Getintouch">
            <h1>Get in Touch</h1>
            <div className="form-block w-form">
                {/* The 'name' attribute's value should be unique to this form */}
                <form onSubmit={handleFormSubmit} data-netlify="true" name="contact-form" method="post">
                    {/* The hidden input 'form-name' should match the form's 'name' attribute */}
                    <input type="hidden" name="form-name" value="contact-form" />

                    {/* Other form fields */}
                    <label htmlFor="name-field" className="field-label-4">Name</label>
                    <input type="text" id="name-field" name="first-name" className="text-field4 w-input" maxLength="256" placeholder="" />

                    <label htmlFor="surname-field" className="field-label-7">Surname</label>
                    <input type="text" id="surname-field" name="surname" className="text-field-7 w-input" maxLength="256" placeholder="" />

                    <label htmlFor="organization-field" className="field-label-5">Organization</label>
                    <input type="text" id="organization-field" name="organization" className="text-field-2 w-input" maxLength="256" placeholder="" required />

                    <label htmlFor="email-address-field" className="field-label-6">Email Address</label>
                    <input type="email" id="email-address-field" name="email-address" className="text-field-3 w-input" maxLength="256" placeholder="" required />

                    <input type="submit" value="Submit" className="submit-button-2 w-button" />
                </form>
            </div>

            {showThankYouModal && (
                <div className="thankyou-modal">
                    <div className="modal-content">
                        <h2>Thank You!</h2>
                        <p>Your submission has been received.</p>
                        <button onClick={closeThankYouModal}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Getintouch;
