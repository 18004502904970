import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './Howitworks.css';
import Rocket from '..//HowImages/1.svg';
import Ops from '..//HowImages/2.svg';
import Any from '..//HowImages/3.svg';
import How1 from '../HowImages/8.svg';
import How2 from '../HowImages/9.svg';
import How3 from '../HowImages/10.svg';
import How4 from '../HowImages/11.svg';
import { Container, Row, Col, Card } from 'react-bootstrap';





function Howitworks() {
    const benefits = [
        {
            title: "Knowledge Retention",
            text: "Nucleus allows a company to retain critical institutional knowledge despite employee turnover. Through capturing the lessons learned from past challenges, Nucleus empowers your team with years of experience ensuring an efficient operation and shorter learning curve.",
            icon: How1
        },
        {
            title: "Record Logging",
            text: "Nucleus has custom form creation functionality, enabling you to digitize any form and ensure no record is ever lost again.",
            icon: How2
        },
        {
            title: "Training Material Access",
            text: "Nucleus provides a user-friendly platform for storing and accessing training materials, ensuring your team always has access to the information they require.",
            icon: How3
        },
        {
            title: "Equipment Management",
            text: "Nucleus provides an economical alternative to current market offerings for managing equipment health and maintenance.",
            icon: How4
        }
    ];

    return (
        <Container id="Benefits">
            <Row >
                <h1 className="mb-5">The Benefits of Nucleus</h1>
                {benefits.map((benefit, index) => (
                    <Col md={6} lg={6} className="mb-5 my-5" key={index}>  
                        <Card className="benefit-card">
                            <div className="card-header">
                                <img src={benefit.icon} alt={benefit.title} className="icon" />
                                <h2>{benefit.title}</h2>
                            </div>
                            <Card.Body className="mb-5 my-5">
                                <Card.Text>{benefit.text}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}


export default Howitworks;
