import React from 'react';
import { Container, Col, Row, Card, Image } from 'react-bootstrap';
import './Features.css';
import Book from '../FeaturesImages/Book.svg';
import Health from '../FeaturesImages/Health.svg';
import Insight from '../FeaturesImages/Insight.svg';
import Lessons from '../FeaturesImages/Lessons.svg';
import Records from '../FeaturesImages/Records.svg';
import Scheduling from '../FeaturesImages/Scheduling.svg';
import Knowledge from '../FeaturesImages/Knowledge.svg'; // Assuming you have an icon for Knowledge Manager

function Features() {
    return (
        <Container className="FeatContainer" id="Features">
            <Row className="">
                <Col>
                    <h1>Features</h1>
                </Col>
            </Row>
            <Row>
                {featuresBlocks.map((block, index) => (
                    <Col key={index} sm={6} md={4} ls={4} className="mb-5">
                        <Card className="features-card">
                            <Card.Body>
                                <Card.Title>
                                    <Image src={block.icon} alt="Icon" className="features-icon" />
                                </Card.Title>
                                <Card.Text>
                                    <h1 className="heading mb-5">{block.Heading}</h1>
                                    <p className="paragraph">{block.text}</p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

const featuresBlocks = [
    {
        icon: Book,
        Heading: 'Knowledge Retention',
        text: 'Nucleus allows a company to retain critical institutional knowledge despite employee turnover. Through capturing the lessons learned from past challenges, Nucleus empowers your team with years of experience ensuring an efficient operation and shorter learning curve.'
    },
    {
        icon: Lessons,
        Heading: 'Training Material Repository',
        text: 'Nucleus provides a user-friendly platform for storing and accessing training materials, ensuring your team always has access to the information they require.'
    },
    {
        icon: Knowledge,
        Heading: 'Knowledge Manager',
        text: 'Nucleus\' AI-driven Knowledge Manager analyzes and provides insightful feedback on logged data, offering personalized mentorship and recommendations to enhance decision-making and ensure continuous improvement.'
    },
    {
        icon: Records,
        Heading: 'Custom Record Creation and reporting',
        text: 'Nucleus provides custom form creation, enabling you to digitize any form and ensure no record is ever lost. Leverage customized reports to prioritize training areas and enhance knowledge retention.'
    },
    {
        icon: Scheduling,
        Heading: 'Maintenance Management',
        text: 'Nucleus provides an economical alternative to current market offerings for managing equipment health and maintenance.'
    },
    
    {
        icon: Health,
        Heading: 'Equipment Health Insights',
        text: 'Obtain detailed reports on your equipment health to prevent downtime and optimize performance.'
    }
    
];

export default Features;
